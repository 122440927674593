mat-form-field {
  &.mat-form-field {
    display: block !important;
  }

  &.mat-form-field.mat-form-field-invalid *,
  &.mat-form-field.mat-form-field-invalid .mat-form-field-label,
  * .mat-placeholder-required {
    color: get-ddm-color("red", 5) !important;
    caret-color: get-ddm-color("red", 5);
  }

  * {
    color: get-ddm-color("grey", 4);
    border-radius: 1px !important;

    &:hover {
      color: get-ddm-color("grey", 7);
    }

    &:active,
    &:focus {
      color: get-ddm-color("grey", 7);
    }

    .mat-form-field-infix {
      @extend %ddm-typo-body-2;
    }

    .mat-placeholder-required {
      color: get-ddm-color("grey", 4) !important;
    }

    label mat-label {
      line-height: to-rem(20px);
      letter-spacing: to-rem(0.5px);
    }
  }
}
