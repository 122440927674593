[ddm-button-toggle-style] {
  @apply h-[40px] w-full flex border-slate-400;

  .mat-button-toggle {
    @apply flex-1 text-slate-500 border-l-slate-400;
  }

  .mat-button-toggle {
    // for important styles
    @apply border-l-slate-400 #{!important};
  }

  .mat-button-toggle-button {
    @apply h-full;

    .mat-button-toggle-label-content {
      @apply text-[14px] leading-none;
    }
  }

  .mat-button-toggle-checked {
    @apply text-blue-500 bg-slate-100;
  }
}
