/* Provides elevations according to the beyonnex style guide */

@import "typography.scss";

$elevation-0:
  to-rem(0px) 1px to-rem(5px) rgba(0, 0, 0, 0.12),
  to-rem(0px) to-rem(2px) to-rem(2px) rgba(0, 0, 0, 0.14),
  to-rem(0px) to-rem(3px) 1px -2px rgba(0, 0, 0, 0.2);

$elevation-1:
  to-rem(0px) to-rem(2px) to-rem(4px) rgba(0, 0, 0, 0.2),
  to-rem(0px) to-rem(4px) to-rem(5px) rgba(0, 0, 0, 0.14),
  to-rem(0px) 1px to-rem(10px) rgba(0, 0, 0, 0.12);

$elevation-2:
  to-rem(0px) to-rem(5px) to-rem(5px) rgba(0, 0, 0, 0.2),
  to-rem(0px) to-rem(8px) to-rem(10px) rgba(0, 0, 0, 0.14),
  to-rem(0px) to-rem(3px) to-rem(14px) rgba(0, 0, 0, 0.12);

$elevation-3:
  to-rem(0px) to-rem(8px) to-rem(10px) rgba(0, 0, 0, 0.2),
  to-rem(0px) to-rem(16px) to-rem(24px) rgba(0, 0, 0, 0.14),
  to-rem(0px) to-rem(6px) to-rem(30px) rgba(0, 0, 0, 0.12);

$elevation-4:
  to-rem(0px) to-rem(11px) to-rem(15px) rgba(0, 0, 0, 0.2),
  to-rem(0px) to-rem(24px) to-rem(38px) rgba(0, 0, 0, 0.14),
  to-rem(0px) to-rem(9px) to-rem(46px) rgba(0, 0, 0, 0.12);
