/* Provides the typographie according to the beyonnex style guide
* body-1 is the font for the html tag and the whole GUI scales with its
* font size.
*/

@import "functions.scss";
@import "colors.scss";

/* inter-regular - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src:
    url("/assets/fonts/inter-v7-latin/inter-v7-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/inter-v7-latin/inter-v7-latin-regular.woff") format("woff"); /* Modern Browsers */
}

/* inter-bold - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src:
    url("/assets/fonts/inter-v7-latin/inter-v7-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/inter-v7-latin/inter-v7-latin-700.woff") format("woff"); /* Modern Browsers */
}

%ddm-typo {
  .light-font {
    @include ddm-light-font-color;
  }
}

/* H5 */
%ddm-typo-h5 {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: to-rem(96px);
  line-height: to-rem(120px);
  letter-spacing: to-rem(-1.5px);
}

/* H4 */
%ddm-typo-h4 {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: to-rem(60px);
  line-height: to-rem(80px);
  letter-spacing: to-rem(-0.5px);
}

/* H3 */
%ddm-typo-h3 {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: to-rem(48px);
  line-height: to-rem(52px);
  //no letter spacing
}

/* H2 */
%ddm-typo-h2 {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: to-rem(34px);
  line-height: to-rem(40px);
  letter-spacing: to-rem(0.25px);
}

/* H1 */
%ddm-typo-h1 {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: to-rem(24px);
  line-height: to-rem(32px);
  letter-spacing: to-rem(0.25px);
}

/* Subtitle 2 */
%ddm-typo-subtitle-2 {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: to-rem(16px);
  line-height: to-rem(20px);
  letter-spacing: to-rem(0.15px);
}

/* Subtitle 1 & Table Header 1 */
%ddm-typo-subtitle-1 {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: to-rem(14px);
  line-height: to-rem(16px);
  letter-spacing: to-rem(0.1px);
}

%ddm-typo-table-body-1 {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: to-rem(14px);
  line-height: to-rem(16px);
  letter-spacing: to-rem(0.1px);
}

/* Body 2 */
%ddm-typo-body-2 {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: to-rem(16px);
  line-height: to-rem(20px);
  letter-spacing: to-rem(0.5px);
}

/* Body 1 -> this is the rem reference*/
%ddm-typo-body-1 {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: to-rem(14px);
  line-height: to-rem(16px);
  letter-spacing: to-rem(0.25px);
  @media (max-width: 1300px) {
    font-size: to-rem((12px));
    line-height: to-rem(14px);
    letter-spacing: to-rem(0.225px);
  }
  color: var(--ddm-font-color);
}

/* Button */
%ddm-typo-button {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: to-rem(14px);
  line-height: to-rem(16px);
  letter-spacing: to-rem(1.25px);
}

/* Caption */
%ddm-typo-caption {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: to-rem(12px);
  line-height: to-rem(14px);
  letter-spacing: to-rem(0.4px);
}

/* OVERLINE */
%ddm-typo-overline {
  @extend %ddm-typo;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: to-rem(10px);
  line-height: to-rem(12px);
  letter-spacing: to-rem(1.5px);
  text-transform: uppercase;
}
