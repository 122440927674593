/* You can add global styles to this file */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "typography.scss";
@import "materialicons.scss";
@import "material-customs";

*:not(text):not(h2) {
  @extend %ddm-typo-body-1;
}

html,
body {
  height: 100%;
  font-size: $CONST-BASE-FONT-SIZE !important;
}

mat-tab-group div.mat-tab-body-wrapper {
  flex-grow: 1;
}

.error-snack-bar {
  background-color: get-ddm-color("red", 2);
  color: get-ddm-color("red", 7);
  span {
    margin: auto;
    text-align: center;
  }
}

.success-snack-bar {
  background-color: get-ddm-color("green", 2);
  color: get-ddm-color("green", 7);
  span {
    margin: auto;
    text-align: center;
  }
}

.empty-spacer {
  flex: 1 1 auto;
}

/* Custom fonts for beyonnex: */

h5 {
  @extend %ddm-typo-h5;
}

h4 {
  @extend %ddm-typo-h4;
}

h3 {
  @extend %ddm-typo-h3;
}

h2 {
  @extend %ddm-typo-h2;
}

h1 {
  @extend %ddm-typo-h1;
}

.subtitle-2 {
  @extend %ddm-typo-subtitle-2;
}

.subtitle-1 {
  @extend %ddm-typo-subtitle-1;
}

.body-2 {
  @extend %ddm-typo-body-2;
}

.body-1 {
  @extend %ddm-typo-body-1;
}

body {
  margin: 0;
  @extend %ddm-typo-body-1;
}

button {
  @extend %ddm-typo-button;
}

.caption {
  @extend %ddm-typo-caption;
}

.overline {
  @extend %ddm-typo-overline;
}

.table-header {
  @extend %ddm-typo-subtitle-1;
}

.icon-display {
  transform: scale(0.7);
}

ace * {
  font-family: monospace !important;
  font-size: $CONST-BASE-FONT-SIZE !important;
  direction: ltr !important;
  text-align: left !important;
}

.advanced-pie-legend {
  .total-value {
    font-size: 28px !important;
    margin-bottom: 4px !important;
  }
  .total-label {
    font-size: 10px !important;
    margin-bottom: 2px !important;
    line-height: 1em !important;
  }
  .legend-items-container {
    .legend-items {
      .legend-item {
        .item-value {
          font-size: 24px !important;
          margin-left: 5px;
          line-height: 1em !important;
          margin-top: 0px !important;
        }
        .item-label {
          font-size: 12px !important;
          opacity: 0.7;
          margin-left: 5px;
          margin-top: 0px !important;
          line-height: 1em !important;
        }
        .item-percent {
          font-size: 12px !important;
          opacity: 0.7;
          margin-left: 5px;
          line-height: 1em !important;
        }
      }
    }
  }
}

.rssi-superior {
  color: rgb(0, 120, 0);
}
.rssi-excellent {
  color: rgb(96, 152, 1);
}
.rssi-good {
  color: rgb(113, 113, 1);
}
.rssi-moderate {
  color: rgb(167, 84, 2);
}
.rssi-too-low {
  color: rgb(138, 1, 1);
}
.rssi-out-of-range {
  color: rgb(215, 0, 0);
}

.ddm-datasheet {
  th {
    padding-right: 10px;
    text-align: left;
    font-weight: bold;
  }
  td {
    text-align: left;
    padding: 0px !important;
  }
}

.pulse {
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease;
}

.mat-tooltip-no-max-width {
  max-width: unset !important;
}

@keyframes pulse {
  from {
  }
  to {
    opacity: 0;
  }
}

.service-order-snackbar {
  background-color: white;
  color: black;

  .snackbar-icon-success,
  .snackbar-icon-error,
  .snackbar-icon-error-default,
  .snackbar-icon-warning {
    color: get-ddm-color("green", 6);

    &::before,
    &::after {
      display: block;
      border-radius: 50%;
      position: absolute;
      content: "";
    }
    &::before {
      width: to-rem(23px);
      height: to-rem(23px);
      border: 2px solid get-ddm-color("green", 4);
      top: to-rem(11px);
      left: to-rem(21px);
    }
    &::after {
      width: to-rem(33px);
      height: to-rem(33px);
      border: 2px solid get-ddm-color("green", 2);
      top: to-rem(6px);
      left: to-rem(16px);
    }
  }

  .snackbar-icon-error {
    color: get-ddm-color("red", 6);

    &::before {
      border: 2px solid get-ddm-color("red", 4);
    }
    &::after {
      border: 2px solid get-ddm-color("red", 2);
    }
  }

  .snackbar-icon-error-default {
    color: get-ddm-color("red", 6);

    &::before {
      border: 2px solid get-ddm-color("red", 4);
    }
    &::after {
      border: 2px solid get-ddm-color("red", 2);
    }
  }

  .snackbar-icon-warning {
    color: get-ddm-color("orange", 6);

    &::before {
      border: 2px solid get-ddm-color("orange", 4);
    }
    &::after {
      border: 2px solid get-ddm-color("orange", 2);
    }
  }

  .snackbar-message {
    max-width: to-rem(330px);
  }
}
