[ddm-expansion-panel-style] {
  &.mat-expansion-panel {
    @apply mt-4 shadow-none;
  }

  .mat-expansion-panel-header {
    @apply h-[44px] px-4 bg-slate-50 text-slate-500 #{!important};

    .mat-content {
      @apply items-center text-base font-bold;

      & > .mat-icon {
        @apply mr-2;
      }
    }

    .mat-expansion-indicator::after {
      @apply border-slate-500;
    }
  }
}
