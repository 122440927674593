[ddm-checkbox-style] {
  &.mat-checkbox {
    .mat-checkbox-inner-container {
      width: 26px;
      height: 26px;
    }

    .mat-checkbox-frame {
      @apply border rounded-sm;
      border-color: #0090f5;
    }

    .mat-checkbox-label {
      @apply text-base text-neutral-950;
      color: #0a0a0a;
    }
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: #0090f5;
    }
  }

  &.mat-checkbox-disabled {
    .mat-checkbox-frame {
      @apply border-gray-500;
    }

    &.mat-checkbox-checked {
      .mat-checkbox-background {
        @apply bg-gray-500 border-gray-500;
      }
    }

    .mat-checkbox-label {
      @apply text-base text-gray-700;
    }
  }
}
