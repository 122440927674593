@import "colors.scss";
@import "functions.scss";

.mat-progress-bar-fill::after {
  background-color: get-ddm-color("blue", 5);
}

.mat-progress-bar {
}

.mat-progress-bar-element {
}
