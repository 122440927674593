@import "elevations.scss";
@import "spacing";

.mat-button-base {
  &:focus {
    outline: none;
  }

  .mat-button-focus-overlay {
    display: none !important;
    outline: none;
  }

  .mat-button-wrapper {
    @extend %ddm-typo-button;

    &,
    mat-icon {
      .mat-button-inline-icon {
        font-size: to-rem(16px);
        line-height: to-rem(16px);
        vertical-align: text-top !important;
      }
    }
  }

  &.mat-primary {
    background: get-ddm-color("blue", 5);
    box-shadow: $elevation-0;
    border-radius: 1px;

    &.mat-icon-only {
      background: transparent;
      box-shadow: none;
      border: none;

      mat-icon {
        color: get-ddm-color("grey", 5);
      }
    }

    &:hover {
      background: get-ddm-color("blue", 6);
    }

    &:disabled {
      background: get-ddm-color("grey", 4);
    }

    &:active {
      border: 0 get-ddm-color("grey", 5);
    }
  }
  &.mat-stroked-button {
    background: white;
    border: 1px solid get-ddm-color("blue", 5);
    border-radius: 1px;

    .mat-button-wrapper {
      color: get-ddm-color("blue", 5);
    }

    &:hover {
      background: white !important;
      border-color: get-ddm-color("blue", 6);

      .mat-button-wrapper {
        color: get-ddm-color("blue", 6);
      }
    }

    &:disabled {
      background: white;
      border-color: get-ddm-color("grey", 4);

      .mat-button-wrapper {
        color: get-ddm-color("grey", 4);
      }
    }
  }
}
