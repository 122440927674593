[ddm-tabs-style] {
  .mat-tab-header {
    border: none;

    .mat-tab-label-container {
      @apply p-2 bg-white rounded-lg border border-gray-200;
    }
  }

  .mat-tab-label-active {
    @apply bg-sky-50 text-blue-500;
  }

  .mat-tab-body {
    @apply mt-8 bg-white rounded-lg border border-gray-200;
  }
}

[ddm-tabs-style-alt] {
  .mat-tab-header {
    border: none;

    .mat-tab-label-container {
      @apply p-2 bg-white rounded-lg border border-gray-200;
    }
  }

  .mat-tab-label-active {
    @apply bg-sky-50 text-blue-500;
  }

  .mat-tab-body {
    @apply mt-8 rounded-lg;
  }
}
