[ddm-chip-list-style] {
  @apply max-h-28 overflow-auto;

  .mat-chip-list-wrapper {
    @apply m-0 gap-3;
  }

  .mat-chip.mat-standard-chip {
    @apply m-0 bg-slate-100 text-slate-500;

    .mat-icon {
      @apply icon-size-[16px] text-slate-500;
    }
  }
}
