/** Register all custom material scss files here **/
@import "material-table";
@import "material-button";
@import "material-form";
@import "material-beyonnex-theme";
@import "material-progress-bar";
@import "material-tabs";
@import "material-button-toggle";
@import "material-chips";
@import "material-expansion-panel";
@import "material-checkbox";
