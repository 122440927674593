/* Provides spacing according to the beyonnex style guide */

@import "functions.scss";

/* use px for calculations but do not forget to use to-rem() on the result*/
$spacing-2-px: 2px;
$spacing-4-px: 4px;
$spacing-8-px: 8px;
$spacing-16-px: 16px;
$spacing-24-px: 24px;
$spacing-32-px: 32px;
$spacing-40-px: 40px;
$spacing-48-px: 48px;
$spacing-64-px: 64px;

$spacing-2: to-rem($spacing-2-px);
$spacing-4: to-rem($spacing-4-px);
$spacing-8: to-rem($spacing-8-px);
$spacing-16: to-rem($spacing-16-px);
$spacing-24: to-rem($spacing-24-px);
$spacing-32: to-rem($spacing-32-px);
$spacing-40: to-rem($spacing-40-px);
$spacing-48: to-rem($spacing-48-px);
$spacing-64: to-rem($spacing-64-px);
