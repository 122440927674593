/* Provides colors according to the beyonnex style guide */

/* This function returns a color that can be selected according to
* the beyonnex style guide.
* Example: get-ddm-color("orange", 4) returns the 4th grade of orange.
*/
@function get-ddm-color($color, $weight) {
  @return map-get(map-get($base-color, $color), $weight);
}

$green: (
  0: #f7fee7,
  1: #ecfccb,
  2: #d9f99d,
  3: #bef264,
  4: #a3e635,
  5: #84cc16,
  6: #65a30d,
  7: #4d7c0f,
  8: #3f6212,
  9: #365314,
);

$red: (
  0: #fef2f2,
  1: #fee2e2,
  2: #fecaca,
  3: #fca5a5,
  4: #f87171,
  5: #ef4444,
  6: #dc2626,
  7: #b91c1c,
  8: #991b1b,
  9: #7f1d1d,
);

$blue: (
  0: #f0f9ff,
  1: #dbf0ff,
  2: #c2e6ff,
  3: #8fd1ff,
  4: #5cbcff,
  5: #0090f5,
  6: #0a73bd,
  7: #005a99,
  8: #034a7c,
  9: #02365a,
);

$grey: (
  0: #f8fafc,
  1: #f1f5f9,
  2: #e2e8f0,
  3: #cbd5e1,
  4: #94a3b8,
  5: #64748b,
  6: #475569,
  7: #334155,
  8: #1e293b,
  9: #0f172a,
);

$orange: (
  0: #fffbeb,
  1: #fef3c7,
  2: #fde68a,
  3: #fcd34d,
  4: #fbbf24,
  5: #f59e0b,
  6: #d97706,
  7: #b45309,
  8: #92400e,
  9: #78350f,
);

$base-color: (
  "blue": $blue,
  "green": $green,
  "red": $red,
  "grey": $grey,
  "orange": $orange,
);

@mixin ddm-light-font-color() {
  color: get-ddm-color("grey", 5);
}

.ddm-color-success {
  color: get-ddm-color("green", 6);
}

.ddm-font-color-blue {
  color: get-ddm-color("blue", 5);
}

.ddm-font-color-grey {
  color: get-ddm-color("grey", 5);
}

:root {
  --ddm-background-color: #ffffff;
}

.dark {
  --ddm-font-color: #ffffff;
  --ddm-background-color: #1e293b;
}
