@use "sass:math";

/* $CONST-BASE-FONT-SIZE is used as rem calculation "0".
* Do not touch.
* Every relation in the GUI layout relates to this value
* so that everythiing looks as intended when the browsers font size
* is default (16px).
*/
$CONST-BASE-FONT-SIZE: 16px;

@function remove-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

/* This function is esential to the correct scaling of the GUI if a
* visual impaired user changes the font size of his browser.
* Every px value from figma or other art tools must be converted by
* this function before it can be used!
*/
@function to-rem($pixels) {
  @return #{math.div(remove-unit($pixels), remove-unit($CONST-BASE-FONT-SIZE))}rem;
}
