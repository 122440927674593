table.mat-table {
  width: 100%;
  cursor: default;

  .mat-header-row,
  .mat-row {
    height: to-rem(36px);
    &:hover {
      background: get-ddm-color("grey", 0);
      th {
        @extend .mat-header-cell;
      }
      td {
        border-color: get-ddm-color("grey", 3);
      }
    }
  }

  .mat-header-cell {
    @extend %ddm-typo-subtitle-1;
  }

  th.mat-header-cell {
    border-color: get-ddm-color("grey", 5);
    @include ddm-light-font-color;
    padding: 0 to-rem(8px);
  }

  td.mat-cell {
    @extend %ddm-typo-table-body-1;
    border-color: get-ddm-color("grey", 3);
    padding: 0 to-rem(8px);
  }
}
